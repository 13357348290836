import { createContext, useState } from "react";
import abs from "../assets/abs.png";
import arms from "../assets/arms.png";
import back from "../assets/back.png";
import chest from "../assets/chest.png";
import { nanoid } from "nanoid";
export const DataContext = createContext();
export default function DataProvider(props) {
  const exerciseData = [
    {
      key: nanoid(),
      name: "Push Ups",
      description:
        "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reiciendis modi omnis ex recusandae quasi optio cumque quisquam id dicta dolor.",

      image: back,
    },
  ];

  const workoutData = [
    {
      key: "workout1",
      name: "Abs",
      description: "Lorem, ipsum dolor sit amet consectetur adipisicing elit.",
      exercises_group: [
        {
          groupName: "Single",
          key: nanoid(),
          exercises: [
            {
              key: nanoid(),
              name: "Push Ups",
              description:
                "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reiciendis modi omnis ex recusandae quasi optio cumque quisquam id dicta dolor.",

              sets: [
                {
                  key: nanoid(),
                  min_reps: "3",
                  max_reps: "10",
                  rest: "2",
                },
                {
                  key: nanoid(),
                  min_reps: "1",
                  max_reps: "4",
                  rest: "2",
                },
              ],
              image: back,
            },
            {
              key: nanoid(),
              name: "Bench Press",
              description:
                "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reiciendis modi omnis ex recusandae quasi optio cumque quisquam id dicta dolor.",

              sets: [
                {
                  min_reps: "3",
                  max_reps: "10",
                  rest: "2",
                },
              ],
              image: back,
            },
          ],
        },
      ],
      tags: ["tag1", "tag2"],
      image: abs,
    },
    {
      key: "workout2",
      name: "Arms",
      description: "Lorem, ipsum dolor sit amet consectetur adipisicing elit.",
      exercises_group: [
        {
          groupName: "Single",
          key: nanoid(),

          exercises: [
            {
              key: nanoid(),
              name: "Push Ups",
              description:
                "Lorem, ipsum dolor sit amet consectetur adipisicing elit",
              sets: [
                {
                  key: nanoid(),
                  min_reps: "3",
                  max_reps: "10",
                  rest: "2",
                },
                {
                  key: nanoid(),
                  min_reps: "1",
                  max_reps: "4",
                  rest: "2",
                },
              ],
              image: back,
            },
            {
              key: nanoid(),
              name: "Bench Press",
              description:
                "Lorem, ipsum dolor sit amet consectetur adipisicing elit",
              sets: [
                {
                  key: nanoid(),
                  min_reps: "1",
                  max_reps: "4",
                  rest: "2",
                },
              ],
              image: back,
            },
          ],
        },
      ],
      tags: ["tag1", "tag3"],
      image: arms,
    },
    {
      key: "workout3",
      name: "Back",
      description: "Lorem, ipsum dolor sit amet consectetur adipisicing elit.",
      exercises_group: [
        {
          groupName: "Superset",
          key: nanoid(),
          exercises: [
            {
              key: nanoid(),
              name: "Push Ups",
              description:
                "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reiciendis modi omnis ex recusandae quasi optio cumque quisquam id dicta dolor.",

              sets: [
                {
                  key: nanoid(),
                  min_reps: "3",
                  max_reps: "10",
                  rest: "2",
                },
                {
                  key: nanoid(),
                  min_reps: "1",
                  max_reps: "4",
                  rest: "2",
                },
              ],
              image: back,
            },
            {
              key: nanoid(),
              name: "Bench Press",
              description:
                "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reiciendis modi omnis ex recusandae quasi optio cumque quisquam id dicta dolor.",

              sets: [
                {
                  key: nanoid(),
                  min_reps: "3",
                  max_reps: "10",
                  rest: "2",
                },
                {
                  key: nanoid(),
                  min_reps: "1",
                  max_reps: "4",
                  rest: "2",
                },
              ],
              image: back,
            },
          ],
          name: "Single",
          exercises: [
            {
              key: nanoid(),
              name: "Pull Ups",
              description:
                "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reiciendis modi omnis ex recusandae quasi optio cumque quisquam id dicta dolor.",

              sets: [
                {
                  key: nanoid(),
                  min_reps: "3",
                  max_reps: "10",
                  rest: "2",
                },
              ],
            },
          ],
        },
      ],

      tags: ["tag2", "tag3"],
      image: back,
    },
  ];

  const programData = [
    {
      key: nanoid(),
      name: "The 4 day work week 2",
      description: "Workout to target your chest muscles.",
      programGoal: "Strength",
      lengthInWeeks: 2,
      splitAsDaysPerWeek: 4,
      weeklyWeightTrainingFrequency: 4,
      tags: ["tag1", "tag2", "tag3"],
      dailyGoals: ["Sleep for 7 hours", "Drink 8 glasses of water"],
      weeklyCardioFrequency: 0,
      weeklyProgressPictureFrequency: 1,
      programWorkoutWeeks: [
        {
          key: nanoid(),
          weeklyGoal: "Week 1 - Mix",
          notes: "Take it slow",
          workoutId: "workout1",
        },
        {
          key: nanoid(),
          weeklyGoal: "Week 2 - Start",
          notes: "Pick up the pace",
          workoutId: "workout2",
        },
      ],
    },
  ];

  const adminData = [
    {
      key: 1,
      name: "cmpnddev",
      email: "cmpnddev@gmail.com",
      password: "********",
    },
    {
      key: 2,
      name: "testAdmin",
      email: "testAdmin@gmail.com",
      password: "******",
    },
  ];

  const [exercisesData, setExercisesData] = useState([]);
  const [workoutsData, setWorkoutsData] = useState([]);
  const [programsData, setProgramsData] = useState([]);
  const [goalsData, setGoalsData] = useState([]);
  const [notificationsData, setNotificationsData] = useState([]);

  const [adminsData, setAdminsData] = useState([]);
  return (
    <DataContext.Provider
      value={{
        exercisesData,
        setExercisesData,
        workoutsData,
        setWorkoutsData,
        programsData,
        setProgramsData,
        goalsData,
        setGoalsData,
        notificationsData,
        setNotificationsData,
        adminsData,
        setAdminsData,
      }}
    >
      {props.children}
    </DataContext.Provider>
  );
}
