import axios from "axios";
import { firebaseAuth } from "../firebase";

let Axios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export async function ApiLogin (adminId)
{
  var token = await firebaseAuth.currentUser.getIdToken();
  return await Axios.get(`users/admin/${adminId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getExercises (pageSize = 10, pageNumber = 1, search = "")
{
  var token = await firebaseAuth.currentUser.getIdToken();
  var endPoint = `exercises?pageSize=${pageSize}&pageNumber=${pageNumber}`;
  if (search)
  {
    endPoint = `exercises?pageSize=${pageSize}&pageNumber=${pageNumber}&search=${search.trim()}`;
  }
  return await Axios.get(endPoint, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getFeedbacks (pageSize = 10, pageNumber = 1, category = "",filterStatus = ""){

  console.log(`feedbacks?category=${category}&pageNumber=${pageNumber}&pageSize=${pageSize}&isRead=${filterStatus}`)

  var token = await firebaseAuth.currentUser.getIdToken();
  return await Axios.get(`feedbacks?category=${category}&pageNumber=${pageNumber}&pageSize=${pageSize}&isRead=${filterStatus}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

}

export async function setFeedbackRead(feedbackId) {
  try {
    // Get the Firebase authentication token
    const token = await firebaseAuth.currentUser.getIdToken();

    // Make the PUT request with the token in the Authorization header
    const response = await Axios.put(
      `feedbacks/mark-read/${feedbackId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    // Return the response data
    return response.data;
  } catch (error) {
    // Handle any errors
    console.error('Error setting feedback as read:', error);
    throw error;
  }
}

export async function createExercise (data)
{
  var token = await firebaseAuth.currentUser.getIdToken();
  return await Axios.post(
    "exercises",
    { ...data },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json; charset=utf-8",
      },
    }
  );
}
export async function createExercises (data)
{
  var token = await firebaseAuth.currentUser.getIdToken();

  return await Axios.post("exercises/bulk", data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
export async function updateExercise (data)
{
  var token = await firebaseAuth.currentUser.getIdToken();
  return await Axios.put(
    `exercises`,
    { ...data },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
}
export async function deleteExercise (id)
{
  var token = await firebaseAuth.currentUser.getIdToken();

  return await Axios.delete(`exercises/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getNotifications ()
{
  var token = await firebaseAuth.currentUser.getIdToken();

  return await Axios.get("notifications", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function createNotification (data)
{
  var token = await firebaseAuth.currentUser.getIdToken();

  return await Axios.post(
    "notifications",
    { ...data },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json; charset=utf-8",
      },
    }
  );
}

export async function getAdmins ()
{
  var token = await firebaseAuth.currentUser.getIdToken();

  return await Axios.get("users/admin", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function createAdmin (data)
{
  var token = await firebaseAuth.currentUser.getIdToken();

  return await Axios.post(
    "users/admin",
    { ...data },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json; charset=utf-8",
      },
    }
  );
}

export async function getWorkouts (pageSize = 10, pageNumber = 1, search = "", tags = [], isDraft = false)
{
  const token = await firebaseAuth.currentUser.getIdToken();

  let endPoint = `workouts/cms?pageSize=${pageSize}&pageNumber=${pageNumber}&isDraft=${isDraft}`;

  if (search)
  {
    endPoint += `&search=${search.trim()}`;
  }

  if (tags && tags.length > 0)
  {
    endPoint += `&tags=${tags.join(',')}`;
  }


  return await Axios.get(endPoint, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}




export async function getWorkout (id)
{
  var token = await firebaseAuth.currentUser.getIdToken();

  return await Axios.get(`workouts/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function createWorkout (data)
{
  var token = await firebaseAuth.currentUser.getIdToken();

  return await Axios.post("workouts", data, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json; charset=utf-8",
    },
  });
}
export async function updateWorkout (data)
{
  var token = await firebaseAuth.currentUser.getIdToken();

  return await Axios.put("workouts", data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}


export async function deleteAllWorkoutIdFromAdhocWorkout (id)
{
  var token = await firebaseAuth.currentUser.getIdToken();

  return await Axios.delete(`ad-hoc-workouts/deleteAdhocWorkoutIds/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
export async function deleteWorkout (id)
{
  var token = await firebaseAuth.currentUser.getIdToken();

  return await Axios.delete(`workouts/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getPrograms (pageSize = 10, pageNumber = 1, search = "", isDraft = false)
{
  var token = await firebaseAuth.currentUser.getIdToken();
  var endPoint = `programs?pageSize=${pageSize}&pageNumber=${pageNumber}&isDraft=${isDraft}`;
  if (search)
  {
    endPoint = `programs?pageSize=${pageSize}&pageNumber=${pageNumber}&isDraft=${isDraft}&search=${search.trim()}`;
  }
  return await Axios.get(endPoint, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getProgram (id)
{
  var token = await firebaseAuth.currentUser.getIdToken();

  return await Axios.get(`programs/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function createProgram (data)
{
  var token = await firebaseAuth.currentUser.getIdToken();

  return await Axios.post("programs", data, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json; charset=utf-8",
    },
  });
}
export async function updateProgram (data)
{
  var token = await firebaseAuth.currentUser.getIdToken();

  return await Axios.put("programs", data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
export async function deleteProgram (id)
{
  var token = await firebaseAuth.currentUser.getIdToken();

  return await Axios.delete(`programs/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getGoals ()
{
  var token = await firebaseAuth.currentUser.getIdToken();

  return await Axios.get("program-goals", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function createGoal (data)
{
  var token = await firebaseAuth.currentUser.getIdToken();

  return await Axios.post("program-goals", data, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json; charset=utf-8",
    },
  });
}
export async function updateGoal (data)
{
  var token = await firebaseAuth.currentUser.getIdToken();

  return await Axios.put("program-goals", data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
export async function deleteGoal (id)
{
  var token = await firebaseAuth.currentUser.getIdToken();

  return await Axios.delete(`program-goals/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getIcons ()
{
  var token = await firebaseAuth.currentUser.getIdToken();

  return await Axios.get("program-icons", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function createIcon (data)
{
  var token = await firebaseAuth.currentUser.getIdToken();

  return await Axios.post("program-icons", data, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json; charset=utf-8",
    },
  });
}
export async function updateIcon (data)
{
  var token = await firebaseAuth.currentUser.getIdToken();

  return await Axios.put("program-icons", data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
export async function deleteIcon (id)
{
  var token = await firebaseAuth.currentUser.getIdToken();

  return await Axios.delete(`program-icons/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getAppSettings ()
{
  var token = await firebaseAuth.currentUser.getIdToken();

  return await Axios.get("app-settings", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getDistinctWorkoutTags ()
{
  var token = await firebaseAuth.currentUser.getIdToken();

  return await Axios.get("workouts/tags/distinct", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getDistinctWorkoutTagsCMS ()
{
  var token = await firebaseAuth.currentUser.getIdToken();

  return await Axios.get("workouts/tags/app", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}



export async function removeTagFromToShowInApp (title)
{
  var token = await firebaseAuth.currentUser.getIdToken();

  return await Axios.delete(`workouts/tags/app/${title}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
export async function addTagToShowInApp (titles)
{
  const token = await firebaseAuth.currentUser.getIdToken();

  return await Axios.post(
    `workouts/tags/app`,
    { titles },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

export async function getApplePricePoints ()
{
  var token = await firebaseAuth.currentUser.getIdToken();

  return await Axios.get("subscriptions/apple-price-points", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
export async function getUsers ()
{
  var token = await firebaseAuth.currentUser.getIdToken();

  return await Axios.get("users?pageNumber=1&pageSize=1000000", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
export async function updateAppVersions (values)
{
  var token = await firebaseAuth.currentUser.getIdToken();
  return await Axios.put(
    `app-settings`,
    { ...values },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
};
