import { Spin } from "antd";
import React from "react";

export default function FullPageLoader() {
  return (
    <div
      className="w-full h-80 flex justify-center items-center"
      style={{ background: "rgba(0, 0, 0, 0.05)" }}
    >
      <Spin />
    </div>
  );
}
