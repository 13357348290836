import React from "react";
import ReactDOM from "react-dom/client";
import Routes from "./Routes";
import AuthProvider from "./context/AuthProvider";
import DataProvider from "./context/DataProvider";
import ThemeProvider from "./context/ThemeProvider";
import { QueryClientProvider, QueryClient } from "react-query";
const queryClient = new QueryClient({
  defaultOptions: { queries: { retry: false, refetchOnWindowFocus: false } },
});
ReactDOM.createRoot(document.getElementById("root")).render(
  <QueryClientProvider client={queryClient}>
    <AuthProvider>
      <DataProvider>
        <ThemeProvider>
          <Routes />
        </ThemeProvider>
      </DataProvider>
    </AuthProvider>
  </QueryClientProvider>
);
