import cmpndText from "../../assets/cmpnd_text.png";
import moon from "../../assets/menu_icons/moon_sm.png";
import sun from "../../assets/menu_icons/sun_sm.png";
import { ThemeContext } from "../../context/ThemeProvider";
import { useContext } from "react";
export default function AppHeader() {
  const { darkTheme, setDarkTheme } = useContext(ThemeContext);
  return (
    <div className="flex-1 flex justify-between items-center">
      <img src={cmpndText} alt="CMPND" data-testid="logo image" />
      <img
        className="cursor-pointer w-8 h-8 transition-transform hover:scale-110"
        onClick={() => setDarkTheme((prevState) => !prevState)}
        src={darkTheme ? moon : sun}
        alt={darkTheme ? "moon" : "sun"}
      />
    </div>
  );
}
