import { Layout, theme } from "antd";
import AppHeader from "../components/appHeader/AppHeader";
import SideMenu from "../components/sideMenu/SideMenu";
import { Outlet } from "react-router-dom";
import AppFooter from "../components/appFooter/AppFooter";
import useIsSmallDevice from "../hooks/useIsSmallDevice";
const { Header, Sider, Content, Footer } = Layout;
export default function DashboardLayout() {
  const { deviceWidth, isSmallDevice } = useIsSmallDevice();
  const {
    token: { colorBgContainer },
  } = theme.useToken({ theme: "dark" });

  return (
    <Layout data-testid="dashboard layout">
      <Header
        className="ps-4 pe-4 sm:ps-8 sm:pe-8 lg:ps-12 lg:pe-12 flex items-center"
        aria-label="header"
      >
        <AppHeader />
      </Header>
      <Layout>
        <Sider
          collapsed={isSmallDevice}
          width={deviceWidth >= 1024 ? 250 : 180}
          style={{ backgroundColor: colorBgContainer }}
        >
          <SideMenu />
        </Sider>
        <Layout className="p-3 lg:p-6">
          <Content
            aria-label="content"
            style={{
              margin: 0,
              minHeight: 280,
            }}
            className="p-3 lg:p-6"
          >
            <div style={{ minHeight: "75vh" }}>
              <Outlet />
            </div>
          </Content>
        </Layout>
      </Layout>
      <Footer
        aria-label="footer"
        className="shadow py-4"
        style={{ background: colorBgContainer }}
      >
        <AppFooter />
      </Footer>
    </Layout>
  );
}
