import { Suspense, lazy } from "react";
import FullPageLoader from "./components/FullPageLoader";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ProtectedRoutes from "./ProtectedRoutes";
import App from "./App";
const Login = lazy(() => import("./pages/login/Login"));
const Dashboard = lazy(() => import("./pages/dashboard/Dashboard"));
const Workouts = lazy(() => import("./pages/workouts/Workouts"));
const Exercises = lazy(() => import("./pages/exercises/Exercises"));
const EditWorkout = lazy(() => import("./pages/workouts/EditWorkout"));
const EditExercise = lazy(() => import("./pages/exercises/EditExercise"));
const AddWorkout = lazy(() => import("./pages/workouts/AddWorkout"));
const Programs = lazy(() => import("./pages/programs/Programs"));
const EditProgram = lazy(() => import("./pages/programs/EditProgram"));
const AddProgram = lazy(() => import("./pages/programs/AddProgram"));
const Goals = lazy(() => import("./pages/goals/Goals"));
const EditGoal = lazy(() => import("./pages/goals/EditGoal"));
const Icons = lazy(() => import("./pages/programIcons/Icons"));
const EditIcon = lazy(() => import("./pages/programIcons/EditIcon"));
const Admins = lazy(() => import("./pages/admins/Admins"));
const Notifications = lazy(() => import("./pages/notifications/Notifications"));
const Feedback = lazy(() => import("./pages/feedbacks/Feedback"));

const Tags = lazy(() => import("./pages/tags/Tags"));
export default function Routes({ children }) {
  const routes = createBrowserRouter([
    {
      path: "/login",
      element: (
        <Suspense fallback={<FullPageLoader />}>
          <Login />
        </Suspense>
      ),
    },
    {
      path: "/",
      element: <ProtectedRoutes />,
      children: [
        {
          path: "/",
          element: <App />,
          children: [
            {
              path: "/",
              index: true,
              element: (
                <Suspense fallback={<FullPageLoader />}>
                  <Dashboard />
                </Suspense>
              ),
            },
            {
              path: "/exercises",
              element: (
                <Suspense fallback={<FullPageLoader />}>
                  <Exercises />
                </Suspense>
              ),
            },
            {
              path: "/exercises/:id/edit",
              element: (
                <Suspense fallback={<FullPageLoader />}>
                  <EditExercise />
                </Suspense>
              ),
            },
            {
              path: "/Tags",
              element: (
                <Suspense fallback={ <FullPageLoader /> }>
                  <Tags />
                </Suspense>
              ),
            },
            {
              path: "/workouts",
              element: (
                <Suspense fallback={<FullPageLoader />}>
                  <Workouts />
                </Suspense>
              ),
            },
            {
              path: "/workouts/add",
              element: (
                <Suspense fallback={<FullPageLoader />}>
                  <AddWorkout />
                </Suspense>
              ),
            },
            {
              path: "/feedbacks",
              element: (
                <Suspense fallback={<FullPageLoader />}>
                  <Feedback/>
                </Suspense>
              ),
            },
            {
              path: "/workouts/:id/edit",
              element: (
                <Suspense fallback={<FullPageLoader />}>
                  <EditWorkout />
                </Suspense>
              ),
            },
            {
              path: "/programs",
              element: (
                <Suspense fallback={<FullPageLoader />}>
                  <Programs />
                </Suspense>
              ),
            },
            {
              path: "/programs/:id/edit",
              element: (
                <Suspense fallback={<FullPageLoader />}>
                  <EditProgram />
                </Suspense>
              ),
            },
            {
              path: "/programs/add",
              element: (
                <Suspense fallback={<FullPageLoader />}>
                  <AddProgram />
                </Suspense>
              ),
            },
            {
              path: "/goals",
              element: (
                <Suspense fallback={<FullPageLoader />}>
                  <Goals />
                </Suspense>
              ),
            },
            // {
            //   path: "/goals/:id/edit",
            //   element: (
            //     <Suspense fallback={<FullPageLoader />}>
            //       <EditGoal />
            //     </Suspense>
            //   ),
            // },

            {
              path: "/icons",
              element: (
                <Suspense fallback={<FullPageLoader />}>
                  <Icons />
                </Suspense>
              ),
            },
            {
              path: "/icons/:id/edit",
              element: (
                <Suspense fallback={<FullPageLoader />}>
                  <EditIcon />
                </Suspense>
              ),
            },

            {
              path: "/notifications",
              element: (
                <Suspense fallback={<FullPageLoader />}>
                  <Notifications />
                </Suspense>
              ),
            },
            {
              path: "/admins",
              element: (
                <Suspense fallback={<FullPageLoader />}>
                  <Admins />
                </Suspense>
              ),
            },
          ],
        },
      ],
    },
  ]);
  return <RouterProvider router={routes}>{children}</RouterProvider>;
}
