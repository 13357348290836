import "./App.css";
import { ConfigProvider, theme } from "antd";
import DashboardLayout from "./layout/DashboardLayout";
import { useContext } from "react";
import { ThemeContext } from "./context/ThemeProvider";

function App() {
  const { darkTheme } = useContext(ThemeContext);
  return (
    <ConfigProvider
      theme={{
        algorithm: darkTheme ? theme.darkAlgorithm : theme.defaultAlgorithm,
      }}
    >
      <DashboardLayout />
    </ConfigProvider>
  );
}

export default App;
