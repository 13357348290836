import { Menu } from "antd";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import dashboard from "../../assets/menu_icons/dashboard.png";
import exercise from "../../assets/menu_icons/fitness.png";
import workout from "../../assets/menu_icons/workout.png";
import program from "../../assets/menu_icons/program.png";
import goal from "../../assets/menu_icons/goal.png";
import icon from "../../assets/menu_icons/icons.png";
import logout from "../../assets/menu_icons/logout.png";
import admin from "../../assets/menu_icons/admin.png";
import feedback from "../../assets/menu_icons/feedback.png";
import { firebaseLogout } from "../../services/auth";
import useAuth from "../../hooks/useAuth";

export default function SideMenu() {
  const location = useLocation();
  const { user } = useAuth();
  // console.log(user);
  const items = [
    {
      label: "Dashboard",
      key: "/",
      icon: <img src={dashboard} alt="dashboard" />,
    },
    {
      label: "Exercises",
      key: "/exercises",
      icon: <img src={exercise} alt="exercises" />,
    },
    {
      label: "Workouts",
      key: "/workouts",
      icon: <img src={workout} alt="workouts" />,
    },
    {
      label: "Programs",
      key: "/programs",
      icon: <img src={program} alt="programs" />,
    },

    {
      label: "Goals",
      key: "/goals",
      icon: <img src={goal} alt="program goal" />,
    },
    {
      label: "Icons",
      key: "/icons",
      icon: <img src={icon} alt="icons" />,
    },
    {
      label: "Notifications",
      key: "/notifications",
      icon: <img src={exercise} alt="notifications" />,
    },
    {
      label: "Tags",
      key: "/tags",
      icon: <img src={ feedback } style={ { width: "16px", height: "16px" } } alt="tags" />,
    },
    {
      label: "Feedbacks",
      key: "/feedbacks",
      icon: <img src={feedback} style={{width:"16px",height:"16px"}} alt="feedback" />,
    },
    user.role == 0 && {
      label: "Admins",
      key: "/admins",
      icon: <img src={admin} alt="admins" />,
    },
    {
      label: "Logout",
      key: "/logout",
      icon: <img src={logout} alt="logout" />,
    },
  ];
  const [selectedKeys, setSelectedKeys] = useState("/");
  useEffect(() => {
    const pathName = location.pathname;
    if (pathName.includes("workouts")) {
      setSelectedKeys("/workouts");
    } else if (pathName.includes("exercises")) {
      setSelectedKeys("/exercises");
    } else if (pathName.includes("admins")) {
      setSelectedKeys("/admins");
    } else if (pathName.includes("programs")) {
      setSelectedKeys("/programs");
    } else if (pathName.includes("goals")) {
      setSelectedKeys("/goals");
    } else if (pathName.includes("icons")) {
      setSelectedKeys("/icons");
    }
    else if (pathName.includes("tags"))
    {
      setSelectedKeys("/tags");
    }
    else if (pathName.includes("notifications"))
    {
      setSelectedKeys("/notifications");
    } else {
      setSelectedKeys(pathName);
    }
  }, [location.pathname]);

  const navigate = useNavigate();
  function handleLogout() {
    firebaseLogout();
  }
  return (
    <Menu
      data-testid="side menu"
      mode="vertical"
      className="border-none self-stretch"
      onClick={(item) => {
        if (item.key === "/logout") {
          handleLogout();
        } else {
          navigate(item.key);
        }
      }}
      selectedKeys={[selectedKeys]}
      items={items}
    ></Menu>
  );
}
