import React, { createContext, useEffect, useState } from "react";
import { firebaseAuth } from "../firebase";
import { onIdTokenChanged } from "firebase/auth";
import { ApiLogin } from "../services/api";
import { Spin } from "antd";
export const AuthContext = createContext();
export default function AuthProvider(props) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  // observer to check when auth state is changing
  // whenever user login or logout from firebase, this observer runs
  // if there is a user that means fireabase login is successfull
  // then try api login if api login fails also logout the user from firebase
  // this is done in catch block
  useEffect(() => {
    const unsubscribe = onIdTokenChanged(firebaseAuth, (user) => {
      if (user) {
        ApiLogin(user.uid)
          .then((res) => {
            setUser({ ...user, role: res.data?.data?.role });
            setLoading(false);
          })
          .catch((err) => {
            setUser(null);
            setLoading(false);
          });
      } else {
        setUser(null);
        setLoading(false);
      }
    });
    return unsubscribe;
  }, []);
  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {loading ? (
        <div
          className="w-screen h-screen flex justify-center items-center"
          style={{ background: "rgba(0, 0, 0, 0.05)" }}
        >
          <Spin />
        </div>
      ) : (
        props.children
      )}
    </AuthContext.Provider>
  );
}
