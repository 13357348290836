import { useState, useEffect } from "react";

function useIsSmallDevice() {
  const [isSmallDevice, setIsSmallDevice] = useState(window.innerWidth < 641);
  const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setDeviceWidth(window.innerWidth);
      setIsSmallDevice(window.innerWidth < 641);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return { deviceWidth, isSmallDevice };
}

export default useIsSmallDevice;
