import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { firebaseAuth } from "../firebase";

export async function firebaseLogin(email, password) {
  return await signInWithEmailAndPassword(firebaseAuth, email, password);
}

export async function firebaseLogout() {
  await signOut(firebaseAuth);
}
